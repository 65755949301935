* {
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
}

h1, h1 * {
  font-family: 'Yanone Kaffeesatz';
  color: #000;
}

h2 {
  font-weight: 800;
  font-size: 2em;
  letter-spacing: .05em;
}

h2, h2 * {
  font-family: 'Yanone Kaffeesatz';
  color: crimson;
}

a {
  color: #0366d6;
}

a[target="_blank"]::after {
  content: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAQElEQVR42qXKwQkAIAxDUUdxtO6/RBQkQZvSi8I/pL4BoGw/XPkh4XigPmsUgh0626AjRsgxHTkUThsG2T/sIlzdTsp52kSS1wAAAABJRU5ErkJggg==);
  margin: 0 3px 0 5px;
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

.page-heading{
  text-align: center;
}

.page-heading h1 {
  position: relative;
  display: inline-block;
}

.page-heading h1 div {
  font-size: 1em;
  font-weight: 600;
}

.page-heading h1::before, .page-heading h1::after {
  background: #333;
  content: " ";
  position: absolute;
  top: 50%;
  height: 2px;
  width: 80px;
  margin: 0 10px;
  display: inline-block;
  zoom: 1;
}


.page-heading h1::before {
  left: 100%;
}

.page-heading h1::after {
  right: 100%;
}

.page {
  display: flex;
  min-height: calc(100vh - 87px);
}

.page .container {
  padding-top: 1em;
}

.sidebar {
  box-sizing: content-box;
  background-color: darkmagenta;
  z-index: 999;
}

.sidebar1 {
  position: absolute;
  left: 0;
  min-width: 60px;
  height: 100%;
  margin-top: -16px;
  padding-bottom: 20px;
  box-sizing: content-box;
  background-color: darkmagenta;
  z-index: 999;
}

.sidebar > .sidebar-toggle {
  text-align: right;
  padding: 5px;;
  padding-right: 15px;
  cursor: pointer;
}

.sidebar > .collapse {
  width: 200px;
  padding: 4px;
}

.sidebar * {
  color: #fff;
  font-size: 1.2rem;
  font-weight: 400;
  font-family: 'Yanone Kaffeesatz';
}

.sidebar ul ul {
  list-style: none;
  margin-left: 1em;
  padding: 0;
}