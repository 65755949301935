a.navbar-brand {
    white-space: normal;
    text-align: center;
    word-break: break-all;
}

html { font-size: 14px; }

@media (min-width: 768px) {
    html { font-size: 16px; }
}

.box-shadow { box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05); }

.navbar-brand span {
    font-family: 'Yanone Kaffeesatz';
    font-size: 2em;
    color: #000000;
}

.navbar-brand span:nth-child(1) {
    font-weight: 800;
}

.navbar-brand span:nth-child(2) {
    margin-left: 10px;
    font-size: 1em;
}

.navbar-brand span:nth-child(3) {
    margin-left: 2px;
    font-size: 1em;
}

.navbar-brand span:nth-child(4) {
    margin-left: 5px;
    font-weight: 800;
    font-size: 1em;
}
