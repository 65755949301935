.job {
    margin-top: 5ex;
    overflow: hidden;
}

.job:nth-child(1) {
    margin-top: 0ex;
}

.job .logo {
    float: left;
    margin-right: 5em;
    padding: 5px;
    height: 200px;
    width: 250px;
    border: 1px solid #eee;
    box-sizing: content-box;
    border-radius: 20px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}

@media (max-width: 768px) {
    .job .logo { display: none; }
}


.job .logo.openpay {
    background-image: url('/public/static/images/openpay-logo.svg');
}

.job .logo.livehire {
    background-image: url('/public/static/images/livehire-logo.png');
}

.job .logo.illion {
    background-image: url('/public/static/images/illion-logo.svg');
}

.job .logo.dnb {
    background-image: url('/public/static/images/dnb-logo.svg');
}

.job > .description {
    overflow: hidden;
}

.job .title {
    font-weight: 600;
    font-size: 1.2em;
    color: darkslateblue;
}
